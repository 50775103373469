@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* =================== Global =================== */
* {
  scroll-behavior: smooth;
}

:root {
  --bg: #fdfdf1;
  --scroll-active: #717171;
	--scrollbg: #35383d;
}

body {
  font-family: Poppins;
  background-color: var(--bg);
  overflow-x: hidden;
}

/* =================== Scrollbar =================== */
::-webkit-scrollbar {
	width: 15px;
}
  
::-webkit-scrollbar-track {
	background-color: var(--scrollbg);
}
  
::-webkit-scrollbar-thumb {
	background-color: #fff;
	border-radius: 20px;
	border: 3px solid transparent;
	background-clip: content-box;
}
  
::-webkit-scrollbar-thumb:hover {
	background-color: var(--scroll-active);
}

::-webkit-scrollbar-thumb:active {
	background-color: var(--scroll-active);
}

/* =================== Video Element Box Shadow =================== */

video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button,
video::-webkit-file-upload-button,
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display {
  display: none;
}

.videoshadow {
  -webkit-box-shadow: 10px 10px 62px -1px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 10px 10px 62px -1px rgba(0, 0, 0, 0.49);
  box-shadow: 10px 10px 62px -1px rgba(0, 0, 0, 0.49);
}

.buttonshadow {
  -webkit-box-shadow: 10px 10px 22px -1px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 10px 10px 22px -1px rgba(0, 0, 0, 0.49);
  box-shadow: 10px 10px 22px -1px rgba(0, 0, 0, 0.49);
}

/* =================== Staggered Fade In =================== */

.anim span {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  display: inline-block;
}

.anim span:nth-of-type(2) {
  animation-delay: 0.3s;
}
.anim span:nth-of-type(3) {
  animation-delay: 0.6s;
}
.anim span:nth-of-type(4) {
  animation-delay: 0.9s;
}
.anim span:nth-of-type(5) {
  animation-delay: 1.2s;
}
.anim span:nth-of-type(6) {
  animation-delay: 2.5s;
}
.anim span:nth-of-type(7) {
  animation-delay: 3s;
}
.anim span:nth-of-type(8) {
  animation-delay: 3.5s;
}

/* =================== Fade In Keyframes =================== */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* =================== Github Corner Keyframes =================== */
.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out infinite;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}